<template>
  <template v-if="isHorizontalList">
    <DelayHydration>
      <div class="absolute left-0 top-0 block size-full bg-white lg:hidden" />
      <HorizontalList
        :list="props.data.list"
        :button="props.headerLink || props.button"
        :button-text="props.buttonText || t('see_all')"
        :header="props.header"
        :header-layout="props.headerLayout"
        :header-position="props.headerPosition"
        :header-color="props.headerColor"
        :labels="CeNewsLabels"
        :settings="props.data.settings"
      />
    </DelayHydration>
  </template>

  <template v-if="isDefaultList">
    <DefaultList
      :data="newsData"
      :header="props.header"
      :header-layout="props.headerLayout"
      :header-position="props.headerPosition"
    />
  </template>

  <template v-if="isExpertsList">
    <DelayHydration>
      <UiBlogTeaserList
        :list="props.data.list"
        :header="props.header"
        :header-layout="props.headerLayout"
        :button-link="props.button?.href"
        :button-text="props.buttonText"
        :gradient="props.appearance?.background"
      />
    </DelayHydration>
  </template>

  <template v-if="isRelatedArticleList">
    <DelayHydration>
      <RelatedArticleList
        :data="newsData"
        :labels="CeNewsLabels"
      />
    </DelayHydration>
  </template>

  <template v-if="isFeaturedList">
    <DelayHydration>
      <div class="xl:flex xl:flex-col xl:gap-x-6">
        <div class="xl:w-2/3">
          <UiHeader
            v-if="props.header"
            v-bind="props"
            class="text-primary"
          />
          <UiNewsListItem
            v-bind="data?.list[0]"
            :labels="CeNewsLabels"
            variant="withColor"
            color-of-gradient="gradient-green"
          />
        </div>
        <div class="xl:w-1/3" />
      </div>
    </DelayHydration>
  </template>

  <template v-if="isForYouList">
    <UiNewsForYouList
      :list="props.data.list"
      :first-el-color="topPromotedNewsColor"
      :last-el-color="bottomPromotedNewsColor"
      :labels="CeNewsLabels"
    />
  </template>

  <template v-if="isTwoColumns">
    <UiHeader
      v-if="props.header"
      v-bind="props"
      class="text-primary"
    />
    <UiNewsTwoCols
      :list="props.data.list"
      :button-text="props.buttonText"
      :button-link="props.button?.href"
    />
  </template>
</template>

<script lang="ts" setup>
import type { CeNewsProps } from './T3CeNews_pi1.types'
import {
  CeNewsLabels,
  useNews,
} from './useNews'

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(defineProps<CeNewsProps>(), {
  button: '',
  buttonText: '',
  header: '',
  headerLayout: 0,
  headerPosition: '',
  headerColor: '',
  headerLink: '',
  subheader: '',
})

const HorizontalList = defineAsyncComponent(() => import('./templates/HorizontalList.vue'))
const DefaultList = defineAsyncComponent(() => import('./templates/DefaultList.vue'))
const RelatedArticleList = defineAsyncComponent(() => import('./templates/RelatedArticleList.vue'))
const UiBlogTeaserList = defineAsyncComponent(() => import('@ui/components/UiBlogTeaserList/UiBlogTeaserList.vue'))
const UiNewsListItem = defineAsyncComponent(() => import('@ui/components/UiNews/UiNewsListItem/UiNewsListItem.vue'))
const UiNewsForYouList = defineAsyncComponent(() => import('@ui/components/UiNews/UiNewsForYouList/UiNewsForYouList.vue'))
const UiNewsTwoCols = defineAsyncComponent(() => import('@ui/components/UiNews/UiNewsTwoCols/UiNewsTwoCols.vue'))
const UiHeader = defineAsyncComponent(() => import('@ui/components/UiHeader/UiHeader.vue'))

const { t } = useI18n()

const newsData = computed(() => props.data)
const {
  isDefaultList,
  isHorizontalList,
  isExpertsList,
  isFeaturedList,
  isRelatedArticleList,
  isForYouList,
  isTwoColumns,
  topPromotedNewsColor,
  bottomPromotedNewsColor,
} = useNews(newsData)
</script>
